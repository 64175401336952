import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Row, Col, Button, Form, Card, Breadcrumb } from 'react-bootstrap';
import Layout from '../../layout/Layout';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { toast } from 'react-toastify';
import { QuestionEditData, QuestionUpdate } from '../../Auth/Api';


const QuestionEdit = () => {

    const Redirect = useNavigate()
    const { id } = useParams()
    const [loading, setloading] = useState(false)
    const [Question, setQuestion] = useState("")
    const [Answer, setAnswer] = useState("")


    const EditData = async () => {
        const Result = await QuestionEditData(id)
        if (Result.data.Status === true) {
            setQuestion(Result.data.Data.question)
            setAnswer(Result.data.Data.answer)
        }
        else {
            toast.error(Result.data.Response_Message)
        }
    }

    const inputvalue = async (e) => {
        setQuestion(e.target.value)
    }

    const inputAnswer = (e, editor) => {
        setAnswer(editor.getData())
    }

    const update = async () => {
        if (Question === "") {
            toast.error('Question Field Is Required')
        }
        else if (Answer === "") {
            toast.error('Answer Field Is Required')
        }
        else {
            setloading(true)
            const Result = await QuestionUpdate(Question, Answer, id)
            if (Result.data.Status === true) {
                toast.success(Result.data.Response_Message)
                setloading(false)
                Redirect(`/question/view/${id}`)
            }
            else {
                toast.error(Result.data.Response_Message)
            }
        }
    }

    useEffect(() => {
        EditData()
    }, [])


    return (
        <>
            {(loading === true) ? <div className="loader" ></div> : <></>}
            <Layout sidebar={true}>
                <div className="page-heading">
                    <h3>Edit Question</h3>
                    <Breadcrumb className="d-none d-lg-block">
                        <Breadcrumb.Item href='/home'>
                            <i className='bx bx-home-alt me-2 fs-5' ></i> Home
                        </Breadcrumb.Item>
                        <Breadcrumb.Item href='/question'>
                            Question
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Edit Question</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className="page-content">
                    <Form >
                        <Row>
                            <Col xs={12}>
                                <Card className="mb-4">
                                    <Card.Body>
                                        <Row>
                                            <Row>
                                                <Col lg={4} sm={12}>
                                                    <Form.Group >
                                                        <Form.Label htmlFor="cate" >Question</Form.Label>
                                                        <Form.Control type="text" id='cate' value={Question} className="my-2" name="question" placeholder='Enter Question' onChange={(e) => { inputvalue(e) }} />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={12}>
                                                    <Form.Label htmlFor="cate" >Answer</Form.Label>
                                                    <CKEditor
                                                        editor={ClassicEditor}
                                                        data={Answer}
                                                        onChange={(e, editor) => inputAnswer(e, editor)}
                                                    />
                                                </Col>
                                            </Row>
                                        </Row>
                                    </Card.Body>
                                    <Card.Footer className="text-end">
                                        <Button variant="primary" className="me-3" onClick={update} >Update</Button>
                                        <Link to={`/question/view/${id}`}>
                                            <Button variant="secondary">Cancle</Button>
                                        </Link>
                                    </Card.Footer>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Layout>
        </>
    )

}

export default QuestionEdit