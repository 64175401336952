import React, { useEffect, useState } from 'react'
import Layout from '../layout/Layout'
import { Card, Col, Form, Row } from 'react-bootstrap'
import { useNavigate, Link } from 'react-router-dom';
import { count } from '../Auth/Api';


const Home = () => {

  const [Stamps, SetStamps] = useState(0)
  const [Questions, SetQuestions] = useState(0)


  const GetCount = async () => {
    const Result = await count()
    if (Result.data.Questions) {
      SetQuestions(Result.data.Questions)
    }

    if (Result.data.Stamps) {
      SetStamps(Result.data.Stamps)
    }
  }

  useEffect(() => {
    GetCount()
  }, [])

  return (
    <Layout sidebar={true}>
      <>
        <div className="vv-dashboard">
          <Row>
            <Col xxl={3} xl={4} md={6}>
              <Card>
                <Card.Body>
                  <Link to="/stamps">
                    <div className="counter orange">
                      <div className="counter-media">
                        <i class='bx bx-star'></i>
                      </div>
                      <div className="counter-content">
                        <h3>{Stamps}</h3>
                        <p>Stamps</p>
                      </div>
                    </div>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
            <Col xxl={3} xl={4} md={6}>
              <Card>
                <Card.Body>
                  <Link to="/question">
                    <div className="counter blue">
                      <div className="counter-media">
                        <i class='bx bx-question-mark'></i>
                      </div>
                      <div className="counter-content">
                        <h3>{Questions}</h3>
                        <p>Question</p>
                      </div>
                    </div>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    </Layout>
  )
}

export default Home;



