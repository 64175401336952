import React from 'react';
import { Link, NavLink } from 'react-router-dom'
import Logo from '../Component/Logo';
import LogoMini from '../Component/Logo-mini';

const Sidebar = ({ sidebar }) => {
  return (
    <>
      <div className={sidebar ? "sidebar-wrapper active" : "sidebar-wrapper"}>
        <div className="sidebar-header">
          <div className="d-flex justify-content-between">
            <div className='sidebar-logo'>
              <Link to="/home">
                <Logo />
                <LogoMini />
              </Link>
            </div>
          </div>
        </div>
        <div className="sidebar-menu">
          <ul className="menu">
            <li className="sidebar-item">
              <NavLink to="/home" className='sidebar-link'>
                <i className='bx bxs-home'></i>
                <span>Dashboard</span>
              </NavLink>
            </li>
            <li className="sidebar-item">
              <NavLink to="/stamps" className='sidebar-link'>
              <i class='bx bx-star'></i>
                <span>Stamps</span>
              </NavLink>
            </li>
            <li className="sidebar-item">
              <NavLink to="/question" className='sidebar-link'>
              <i class='bx bx-question-mark'></i>
                <span>Questions</span>
              </NavLink>
            </li>
          </ul>
          
        </div>
      </div>
    </>
  )
}

export default Sidebar;