import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { StampView } from '../../Auth/Api';
import Fancybox from '../../Component/FancyBox';
import Layout from '../../layout/Layout';

const StampsView = () => {

    const [Data, SetData] = useState([])
    const [loading, setloading] = useState(false)
    const { id } = useParams()

    const GetData = async () => {
        setloading(true)
        const Result = await StampView(id)
        if (Result.data.Status === true) {
            setloading(false)
            SetData(Result.data.Data)
        }
    }

    useEffect(() => {
        GetData()
    }, [])

    return (
        <>
            {(loading === true) ? <div className="loader" ></div> : <></>}
            <Layout sidebar={true}>
                <div div className="page-heading">
                    <h3><Link to="/stamps" className='btn btn-primary btn-icon me-3'><i className='bx bxs-left-arrow-alt'></i></Link>View Stamps</h3>
                    <Row className="align-items-center">
                        <Col md="auto" xs={6}>
                            <Link to={`/stamps/edit/${id} `}>
                                <Button variant="primary" value="edit">Edit</Button>
                            </Link>
                        </Col>
                    </Row>
                </div>
                <div className='page-content'>
                    {/* {loading === true ? <div className='loader table-loader'></div> : ""} */}
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>NO.</p><span>1</span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Main Image</p><span>
                                            {
                                                <Fancybox>
                                                    <a data-fancybox="gallery" href={Data.main_image}>
                                                        <img src={Data.main_image} height={40} className='rounded-3' />
                                                    </a>
                                                </Fancybox>
                                            }
                                        </span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Front Image</p><span>
                                            {
                                                <Fancybox>
                                                    <a data-fancybox="gallery" href={Data.front_image}>
                                                        <img src={Data.front_image} height={40} className='rounded-3' />
                                                    </a>
                                                </Fancybox>
                                            }
                                        </span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Zip</p><span>
                                            {
                                                <a download="GFG" href={Data.zip_name} >
                                                    <Button variant="outline-primary" size="sm" className="btn-icon">
                                                        <i className='bx bxs-download'></i>
                                                    </Button>
                                                </a>
                                            }
                                        </span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Is Premium</p><span>{(Data.is_premium === 1) ? "Active" : "Deactive"}</span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Status</p><span>{(Data.status === 1) ? "Active" : "Deactive"}</span>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </div>
            </Layout>
        </>
    )
}

export default StampsView;