import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react'
import { Button, Container, Card, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import validator from 'validator';
import { UserLogin } from '../Auth/Api';

const Login = () => {

  const Redirect = useNavigate()
  const [Data, SetData] = useState({
    Email: Cookies.get('email-Gallary') ? Cookies.get('email-Gallary') : "",
    Password: Cookies.get('password-Gallary') ? Cookies.get('password-Gallary') : "",
    Remeber: Cookies.get('email-Gallary') && Cookies.get('password-Gallary') ? true : false
  })

  const inputdata = (e) => {
    SetData({ ...Data, [e.target.name]: e.target.value })
  }

  const Remeber = (e) => {
    SetData({ Email: Data.Email, Password: Data.Password, Remeber: e.target.checked })
  }

  const login = async (e) => {
    e.preventDefault()
    if (Data.Remeber === false) {
      Cookies.remove('email-Gallary')
      Cookies.remove('password-Gallary')
    }
    else {
      Cookies.set('email-Gallary', Data.Email)
      Cookies.set('password-Gallary', Data.Password)
    }

    if (Data.Email === "") {
      toast.error("Email Id Field Is Required")
    }
    else if (!validator.isEmail(Data.Email)) {
      toast.error("Enter Your Valid Email Id")
    }
    else if (Data.Password === "") {
      toast.error("Password Field Is Required")
    }
    else {
      const Result = await UserLogin(Data)
      if(Result.data.Status === true)
      {
        toast.success(Result.data.Response_Message)
        Cookies.set('jwt-Gallary', Result.data.token, { expires: 1 })
        console.log("hello")
        Redirect('/home')
      }
      else
      {
        toast.error(Result.data.Response_Message)
      }
    }
  }

  const [eye, seteye] = useState(true);
  const [password, setpassword] = useState("password");
  const [type, settype] = useState(false);

  const Eye = () => {
    if (password == "password") {
      setpassword("text");
      seteye(false);
      settype(true);
    }
    else {
      setpassword("password");
      seteye(true);
      settype(false);
    }
  }
  return (
    <>
      <Container>
        <div className="auth">
          <div className="auth-box">
            <Card>
              <Card.Header className="pb-0">
                <div className='auth-logo'>
                  <img src="../logo/app-logo.svg" alt="Vasundhara Infotech" className='logo-mini' />
                </div>
              </Card.Header>
              <Card.Body>
                <Form method='post' onSubmit={login}>
                  <h1 className='auth-title'>Sign in</h1>
                  <h2 className='auth-subtitle'>Gallary</h2>
                  <Form.Group className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="text" className="my-2" name='Email' placeholder="Enter Your Email" value={Data.Email} onChange={(e) => { inputdata(e) }} />
                  </Form.Group>
                  <Form.Group className="mb-4 input-prefix">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type={password} className="my-2" name='Password' placeholder="Enter Password" value={Data.Password} onChange={(e) => { inputdata(e) }} />
                    <i onClick={Eye} className={`bx ${eye ? "bx-hide" : "bx-show"}`}></i>
                  </Form.Group>
                  <Form.Group className="mb-4" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Remember Me" onClick={e => Remeber(e)} checked={Data.Remeber} />
                  </Form.Group>
                  <Button variant="primary" type="submit" className="w-100 " >Sign In</Button>
                </Form>
              </Card.Body>
            </Card>
          </div>
        </div>
      </Container>
    </>
  )
}

export default Login;