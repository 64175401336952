import React from "react";

const Error=()=>{
    return(
    <>  
        <img src="../../not-found/subcategory.svg" style={{width:"50%",height:"50vh",marginTop:"100px",marginLeft:"500px"}} alt="" />
        <h1 style={{marginLeft:"800px"}}>No Data Fond</h1>
        </>
    )
}

export default Error;