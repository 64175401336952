import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Button, Card, Col, Form, Row, Table } from "react-bootstrap";
import Layout from '../../layout/Layout';
import $ from 'jquery'
import { QuestionAll, QuestionDelete, QuestionSearch } from '../../Auth/Api';
import Pagination from 'rc-pagination';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';


const Question = () => {

  const [loading, setloading] = useState(true)
  const [iconcoror, Seticoncoror] = useState("")
  const [Data, SetData] = useState([])

  const GetData = async () => {
    const Result = await QuestionAll()
    if (Result.data.Status === false) {
      $('#remove_tr').empty()
      $('#remove_tr').append('<td colSpan="100%" class="p-0"><div class="no-found"><img src="../../not-found/quotes.svg"/><p>No Found Question</p></div></td>')
      setloading(false)
      SetData([])
    }
    else {
      $('#remove_tr').empty()
      SetData(Result.data.Data)
      setloading(false)
    }
  }

  const sorting = (col, type = "string", order, e) => {
    Seticoncoror(e.target.id)
    if (order === "ASC") {
      const sorted = [...Data].sort((a, b) =>
       a[col].toLowerCase().trim() > b[col].toLowerCase().trim() ? 1 : -1
      );
      if (iconcoror !== e.target.id) {
        SetData(sorted)
      }
    }
    if (order === "DSC") {
      const sorted = [...Data].sort((a, b) =>
        a[col].toLowerCase().trim() < b[col].toLowerCase().trim() ? 1 : -1
      );
      if (iconcoror !== e.target.id) {
        SetData(sorted)
      }
    }
  }

  const DeleteQuestion = (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger me-2",
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel! ",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          const Result = await QuestionDelete(id)
          if (Result.data.Status === true) {
            toast.success(Result.data.Response_Message)
            GetData()
            if(current !== 1)
            {
              setCurrent(current - 1)
            }
          }
        }
      });
  }

  const search = async (e) => {
    const Result = await QuestionSearch(e.target.value)
    if (Result.data.Status === false) {
      $('#remove_tr').empty()
      $('#remove_tr').append('<td colSpan="100%" class="p-0"><div class="no-found"><img src="../../not-found/quotes.svg"/><p>No Found Question</p></div></td>')
      SetData([])

    }
    else {
      $('#remove_tr').empty()
      SetData(Result.data.Data)
    }

  }

  // Paggintion Code // 
  const [perPage, setPerPage] = useState(10);
  const [size, setSize] = useState(perPage);
  const [current, setCurrent] = useState(1);

  const getData = (current, pageSize) => {
    return Data.slice((current - 1) * pageSize, current * pageSize);
  };

  const PerPageChange = (value) => {
    setSize(value);
    const newPerPage = Math.ceil(Data.length / value);
    if (current > newPerPage) {
      setCurrent(newPerPage);
    }
  }

  const PaginationChange = (page, pageSize) => {
    setCurrent(page);
    setSize(pageSize)
  }


  const PrevNextArrow = (current, type, originalElement) => {
    if (type === 'prev') {
      return <button className='paggination-btn'>Previous</button>;
    }
    if (type === 'next') {
      return <button className='paggination-btn'>Next</button>;
    }
    return originalElement;
  }

  useEffect(() => {
    GetData()
  }, [])

  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3 className="my-1">Question</h3>
        <Row className="align-items-center">
          <Col md="auto" xs={12}>
            <Form.Control type="text" name="name" id="" placeholder="Search Question" className="my-1" onChange={(e) => { search(e) }} />
          </Col>
          <Col md="auto" xs={6}>
            <Form.Select onChange={e => { setSize(Number(e.target.value)); setCurrent(1) }} className="wv-100 my-1">
              {
                [10, 25, 50, 100].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))
              }
            </Form.Select>
          </Col>
          <Col md="auto" xs={6}>
            <Link to="/question/add">
              <Button variant="primary" className='my-1' value="create">Add New</Button>
            </Link>
          </Col>
        </Row>
      </div>
      <div className="page-content">
        <Row>
          <Col xs={12}>
            <Card>
              <Card.Body>
                {(loading === true) ? <div className="loader table-loader" ></div> : <></>}
                <Table bordered responsive id="myTable">
                  <thead>
                    <tr>
                      <th width="2%" style={{ textAlign: "center" }}>
                        No
                      </th>
                      <th width="10%">
                      <div className='table-sort-filter'> Question
                        {/* <span className='table-sort'>
                          <div className={`sort-down ${iconcoror === "question_down" ? "active" : ""}`} id="question_down" onClick={(e) => { sorting('question', "", "ASC", e) }} ></div>
                          <div className={`sort-up ${iconcoror === "question_up" ? "active" : ""}`} id="question_up" onClick={(e) => { sorting('question', "", "DSC", e) }} ></div>
                        </span> */}
                      </div>
                      </th>
                      <th width="15%">
                        Answer
                      </th>
                      <th width="7%" className='text-center'>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      getData(current, size).map((val, index) => {
                        return (
                          <tr key={index}>
                            <td className="text-center">{(current === 1) ? index + 1 : current * size + index + 1 - size}</td>
                            <td>{val.question}</td>
                            <td><div className='line-clamp'>{val.answer}</div></td>
                            <td className="text-center">
                              <Link to={`/question/view/${val._id}`}>
                                <Button variant="outline-warning" size="sm" className="btn-icon me-2"><i className='bx bx-show'></i></Button>
                              </Link>
                              <Button variant="outline-danger" size="sm" className="btn-icon" onClick={() => DeleteQuestion(val._id)} ><i className='bx bx-trash-alt' ></i></Button>
                            </td>
                          </tr>
                        )
                      })
                    }
                    <tr id="remove_tr"></tr>

                  </tbody>
                </Table>
                {(Data.length > size) ?
                  <div className="pagination-custom">
                    <Pagination
                      className="pagination-data"
                      onChange={PaginationChange}
                      total={Data.length}
                      current={current}
                      pageSize={size}
                      showSizeChanger={false}
                      itemRender={PrevNextArrow}
                      onShowSizeChange={PerPageChange}
                      showTitle={false}
                    />
                  </div> : ""}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </Layout>
  )
}

export default Question