import axios from "axios";
import { Form } from "react-router-dom";
import Login from "../pages/Login";


const CatchError = (error) => {
    return ({ Status: false, Response_Code: 404, Response_Message: error.message })
}

export const StampApi = async (data) => {
    try {
        const from = new FormData()
        from.append('main_image', data.main_images)
        from.append('front_image', data.front_images)
        from.append('zip_name', data.zip)
        from.append('status', data.status)
        from.append('is_premium', data.premium)

        const Response = await axios.post('/stamp', from)
        return Response

    } catch (error) {
        return CatchError(error)
    }
}

export const StampAll = async (data) => {
    try {
        const Response = await axios.post('/stamp/all')
        return Response
    } catch (error) {
        return CatchError(error)
    }
}

export const StampDelete = async (id) => {
    try {
        const form = new FormData()
        form.append('_id', id)
        const Response = await axios.post('/stamp/delete', form)
        return Response
    } catch (error) {
        return CatchError(error)
    }
}

export const StampStausUpdate = async (Status, id) => {
    try {
        const form = new FormData()
        form.append('_id', id)
        form.append('status', Status)
        const Response = await axios.post('/stamp/status_update', form)
        return Response
    } catch (error) {
        return CatchError(error)
    }
}

export const StampPremiumStausUpdate = async (Premium, id) => {
    try {
        const form = new FormData()
        form.append('_id', id)
        form.append('is_premium', Premium)
        const Response = await axios.post('/stamp/is_premium_update', form)
        return Response
    } catch (error) {
        return CatchError(error)
    }
}

export const StampView = async (id) => {
    try {
        const form = new FormData()
        form.append('_id', id)
        const Response = await axios.post('/stamp/view', form)
        return Response
    } catch (error) {
        return CatchError(error)
    }
}

export const StampEdit = async (id) => {
    try {
        const form = new FormData()
        form.append('_id', id)
        const Response = await axios.post('/stamp/edit', form)
        return Response
    } catch (error) {
        return CatchError(error)
    }
}

export const StampUpdate = async (data, id) => {
    try {
        const from = new FormData()
        from.append('main_image', data.main_images)
        from.append('front_image', data.front_images)
        from.append('zip_name', data.zip)
        from.append('status', data.status)
        from.append('is_premium', data.premium)
        from.append('_id', id)

        const Response = await axios.post('/stamp/update', from)
        return Response

    } catch (error) {
        return CatchError(error)
    }
}

export const StampSearch = async (premium, status) => {
    try {
        const from = new FormData()
        from.append('premium', premium)
        from.append('status', status)
        const Response = await axios.post('/stamp/search', from)
        return Response
    } catch (error) {
        return CatchError(error)
    }
}

export const QuestionApi = async (data) => {
    try {
        const from = new FormData()
        from.append('question', data.question)
        from.append('answer', data.answer)

        const Response = await axios.post('/questions', from)
        return Response

    } catch (error) {
        return CatchError(error)
    }
}

export const QuestionAll = async (data) => {
    try {
        const Response = await axios.post('/questions/all')
        return Response
    } catch (error) {
        return CatchError(error)
    }
}

export const QuestionDelete = async (id) => {
    try {
        const form = new FormData()
        form.append('_id', id)
        const Response = await axios.post('/questions/delete', form)
        return Response
    } catch (error) {
        return CatchError(error)
    }
}

export const Questionview = async (id) => {
    try {
        const form = new FormData()
        form.append('_id', id)
        const Response = await axios.post('/questions/view', form)
        return Response
    } catch (error) {
        return CatchError(error)
    }
}

export const QuestionAllView = async (data) => {
    try {
        const Response = await axios.post('/questions/all/view')
        return Response
    } catch (error) {
        return CatchError(error)
    }
}

export const QuestionEditData = async (id) => {
    try {
        const form = new FormData()
        form.append('_id', id)
        const Response = await axios.post('/questions/edit', form)
        return Response
    } catch (error) {
        return CatchError(error)
    }
}

export const QuestionUpdate = async (Question, Answer, id) => {
    try {
        const from = new FormData()
        from.append('question', Question)
        from.append('answer', Answer)
        from.append('_id', id)
        const Response = await axios.post('/questions/update', from)
        return Response

    } catch (error) {
        return CatchError(error)
    }
}

export const QuestionSearch = async (Question) => {
    try {
        const from = new FormData()
        from.append('question', Question)
        const Response = await axios.post('/questions/search', from)
        return Response
    } catch (error) {
        return CatchError(error)
    }
}

export const count = async () => {
    try {
        const Response = await axios.post('/count')
        return Response
    } catch (error) {
        return CatchError(error)
    }
}

export const UserLogin = async (Data) => {
    try {
        const from = new FormData()
        from.append('email', Data.Email)
        from.append('password', Data.Password)
        const Response = await axios.post('/login', from)
        return Response
    } catch (error) {
        return CatchError(error)
    }
}

export const LogoutApi = async () => {
    try {
        const Response = await axios.post('/logout')
        return Response
    } catch (error) {
        return CatchError(error)
    }
}