import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Button, Card, Col, Form, Row, Table } from "react-bootstrap";
import Layout from '../../layout/Layout';
import Fancybox from '../../Component/FancyBox';
import Switch from 'react-switch'
import { StampAll, StampDelete, StampPremiumStausUpdate, StampSearch, StampStausUpdate } from '../../Auth/Api';
import $ from 'jquery'
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import Pagination from 'rc-pagination';

const Stamps = () => {

  const [loading, setloading] = useState(true)
  const [Data, SetData] = useState([])
  const [iconcoror, Seticoncoror] = useState("")
  const [search, setsearch] = useState({
    premium: "",
    status: ""
  })

  const GetData = async () => {
    const Result = await StampAll()
    if (Result.data.Status === false) {
      $('#remove_tr').empty()
      $('#remove_tr').append('<td colSpan="100%" class="p-0"><div class="no-found"><img src="../../not-found/quotes.svg"/><p>No Found Stamps</p></div></td>')
      setloading(false)
      SetData([])
    }
    else {
      $('#remove_tr').empty()
      SetData(Result.data.Data)
      setloading(false)
    }
  }

  const sorting = (col, type = "string", order, e) => {
    Seticoncoror(e.target.id)
    if (order === "ASC") {
      const sorted = [...Data].sort((a, b) =>
        parseInt(a[col]) > parseInt(b[col]) ? 1 : -1
      );
      console.log(sorted)
      if (iconcoror !== e.target.id) {
        SetData(sorted)
      }
    }
    if (order === "DSC") {
      const sorted = [...Data].sort((a, b) =>
        parseInt(a[col]) < parseInt(b[col]) ? 1 : -1
      );
      if (iconcoror !== e.target.id) {
        SetData(sorted)
      }
    }
  }

  const DeleteStamp = (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger me-2",
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel! ",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          const Result = await StampDelete(id)
          if (Result.data.Status === true) {
            toast.success(Result.data.Response_Message)
            GetData()
            if (current !== 1) {
              setCurrent(current - 1)
            }
          }
        }
      });
  }

  const StatusUpdate = async (e, id) => {
    const Status = (e === true) ? 1 : 0
    const Result = await StampStausUpdate(Status, id)
    if (Result.data.Status === true) {
      toast.success("Status Update Successfully..")
      GetData()
    }
    else {
      toast.error(Result.data.Response_Message)
    }
  }

  const PremiumUpdate = async (e, id) => {
    const Premium = (e === true) ? 1 : 0
    const Result = await StampPremiumStausUpdate(Premium, id)
    if (Result.data.Status === true) {
      toast.success("Is Premium Update Successfully..")
      GetData()
    }
    else {
      toast.error(Result.data.Response_Message)
    }
  }

  const searchdata = async (premium, status) => {
    premium = (premium === "") ? search.premium : premium
    status = (status === "") ? search.status : status
    const Result = await StampSearch(premium, status)
    console.log(Result)
    if (Result.data.Status === false) {
      $('#remove_tr').empty()
      $('#remove_tr').append('<td colSpan="100%" class="p-0"><div class="no-found"><img src="../../not-found/quotes.svg"/><p>No Found Stamps</p></div></td>')
      SetData([])
    }
    else {
      $('#remove_tr').empty()
      SetData(Result.data.Data)
      setCurrent(1)
    }
  }

  const premium = (e) => {
    setsearch({ ...search, [e.target.name]: e.target.value })
    searchdata(e.target.value, "")
  }

  const status = (e) => {
    setsearch({ ...search, [e.target.name]: e.target.value })
    searchdata("", e.target.value)
  }

  // Paggintion Code // 
  const [perPage, setPerPage] = useState(10);
  const [size, setSize] = useState(perPage);
  const [current, setCurrent] = useState(1);

  const getData = (current, pageSize) => {
    return Data.slice((current - 1) * pageSize, current * pageSize);
  };

  const PerPageChange = (value) => {
    setSize(value);
    const newPerPage = Math.ceil(Data.length / value);
    if (current > newPerPage) {
      setCurrent(newPerPage);
    }
  }

  const PaginationChange = (page, pageSize) => {
    setCurrent(page);
    setSize(pageSize)
  }


  const PrevNextArrow = (current, type, originalElement) => {
    if (type === 'prev') {
      return <button className='paggination-btn'>Previous</button>;
    }
    if (type === 'next') {
      return <button className='paggination-btn'>Next</button>;
    }
    return originalElement;
  }

  useEffect(() => {
    GetData()
  }, [])

  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3 className="my-1">Stamps</h3>
        <Row className="align-items-center">
          <Col md="auto" xs={12}>
            <Form.Select className='my-1' name='premium' onChange={(e) => premium(e)} >
              <option value="" disabled>Select Premium</option>
              <option value="All">All Premium</option>
              <option value="1">ON</option>
              <option value="0">OFF</option>
            </Form.Select>
          </Col>
          <Col md="auto" xs={12}>
            <Form.Select className='my-1' name='status' onChange={(e) => status(e)} >
              <option value="" disabled>Select Status</option>
              <option value="All">All Status</option>
              <option value="1">ON</option>
              <option value="0">OFF</option>
            </Form.Select>
          </Col>
          <Col md="auto" xs={6}>
            <Form.Select onChange={e => { setSize(Number(e.target.value)); setCurrent(1) }} className="wv-100 my-1">
              {
                [10, 25, 50, 100].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))
              }
            </Form.Select>
          </Col>
          <Col md="auto" xs={6}>
            <Link to="/stamps/add">
              <Button variant="primary" className='my-1' value="create">Add New</Button>
            </Link>
          </Col>
        </Row>
      </div>
      <div className="page-content">
        <Row>
          <Col xs={12}>
            <Card>
              <Card.Body>
                {(loading === true) ? <div className="loader table-loader" ></div> : <></>}
                <Table bordered responsive id="myTable">
                  <thead>
                    <tr>
                      <th width="5%" style={{ textAlign: "center" }}> No </th>
                      <th width="15%" style={{ textAlign: "center" }}> Main Image </th>
                      <th width="15%" style={{ textAlign: "center" }}> Front Image </th>
                      <th width="15%" style={{ textAlign: "center" }}> Zip Name </th>
                      <th width="12%"><div className='table-sort-filter justify-content-center'> Is Premium
                        <span className='table-sort'>
                          <div className={`sort-down ${iconcoror === "is_premium_down" ? "active" : ""}`} id="is_premium_down" onClick={(e) => { sorting('is_premium', "", "DSC", e) }} ></div>
                          <div className={`sort-up ${iconcoror === "is_premium_up" ? "active" : ""}`} id="is_premium_up" onClick={(e) => { sorting('is_premium', "", "ASC", e) }} ></div>
                        </span>
                      </div>
                      </th>
                      <th width="12%"><div className='table-sort-filter justify-content-center'> Status
                        <span className='table-sort'>
                          <div className={`sort-down ${iconcoror === "status_down" ? "active" : ""}`} id="status_down" onClick={(e) => { sorting('status', "", "DSC", e) }} ></div>
                          <div className={`sort-up ${iconcoror === "status_up" ? "active" : ""}`} id="status_up" onClick={(e) => { sorting('status', "", "ASC", e) }} ></div>
                        </span>
                      </div></th>
                      <th width="12%" className='text-center'>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      getData(current, size).map((val, index) => {
                        return (
                          <tr key={index}>
                            <td className="text-center">{(current === 1) ? index + 1 : current * size + index + 1 - size}</td>
                            <td className="text-center">
                              <Fancybox>
                                <a data-fancybox="gallery" href={val.main_image}>
                                  <img src={val.main_image}  height={40} alt="Main Image" />
                                </a>
                              </Fancybox>
                            </td>
                            <td className="text-center">
                              <Fancybox>
                                <a data-fancybox="gallery" href={val.front_image}>
                                  <img src={val.front_image}  height={40} alt="Front Image" />
                                </a>
                              </Fancybox>
                            </td>
                            <td className="text-center">
                              <a download="GFG" href={val.zip_name} >
                                <Button variant="outline-primary" size="sm" className="btn-icon ms-2">
                                  <i className='bx bxs-download'></i>
                                </Button>
                              </a>
                            </td>
                            <td className="text-center">
                              <Switch
                                onChange={(e) => { PremiumUpdate(e, val._id) }}
                                checked={(val.is_premium === 1) ? true : false}
                                offColor="#C8C8C8"
                                onColor="#0093ed"
                                height={30}
                                width={70}
                                className="react-switch"
                                uncheckedIcon={
                                  <div className='react-switch-off'>OFF</div>
                                }
                                checkedIcon={
                                  <div className='react-switch-on'>ON</div>
                                }
                              />
                            </td>
                            <td className="text-center">
                              <Switch
                                onChange={(e) => { StatusUpdate(e, val._id) }}
                                checked={(val.status === 1) ? true : false}
                                offColor="#C8C8C8"
                                onColor="#0093ed"
                                height={30}
                                width={70}
                                className="react-switch"
                                uncheckedIcon={
                                  <div className='react-switch-off'>OFF</div>
                                }
                                checkedIcon={
                                  <div className='react-switch-on'>ON</div>
                                }
                              />
                            </td>
                            <td className="text-center">
                              <Link to={`/stamps/view/${val._id}`}>
                                <Button variant="outline-warning" size="sm" className="btn-icon me-2"><i className='bx bx-show'></i></Button>
                              </Link>
                              <Button variant="outline-danger" size="sm" className="btn-icon" onClick={() => DeleteStamp(val._id)}><i className='bx bx-trash-alt' ></i></Button>
                            </td>
                          </tr>
                        )
                      })
                    }
                    <tr id="remove_tr"></tr>
                  </tbody>
                </Table>
                {(Data.length > size) ?
                  <div className="pagination-custom">
                    <Pagination
                      className="pagination-data"
                      onChange={PaginationChange}
                      total={Data.length}
                      current={current}
                      pageSize={size}
                      showSizeChanger={false}
                      itemRender={PrevNextArrow}
                      onShowSizeChange={PerPageChange}
                      showTitle={false}
                    />
                  </div> : ""}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </Layout>
  )
}

export default Stamps;