import { BrowserRouter, Routes, Route, HashRouter, Navigate, Outlet } from 'react-router-dom';
// import 'bootstrap/dist/css/bootstrap.min.css';
import './bootstrap.css';
import 'boxicons/css/boxicons.css';
import Home from './pages/Home';
import Login from './pages/Login';
import Question from './pages/Question/Question'
import QuestionAdd from "./pages/Question/QuestionAdd"
import QuestionEdit from './pages/Question/QuestionEdit'
import Questionview from "./pages/Question/QuestionView"
import Stamps from './pages/Stamps/Stamps';
import StampsAdd from './pages/Stamps/StampsAdd';
import StampsEdit from './pages/Stamps/StampsEdit';
import Error from './pages/Error/Error'
import './App.css';
import './utilities.css';
import axios from 'axios'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import StampsView from './pages/Stamps/StampsView';
import Cookies from 'js-cookie';

function App() {
  axios.defaults.baseURL = process.env.REACT_APP_API_LINK
  const ProtectedRoute = ({ redirectPath = "/" }) => {
    if (!Cookies.get('jwt-Gallary')) {
      return <Navigate to={redirectPath} replace />
    }
    else {
      const token = `Bearer ${Cookies.get('jwt-Gallary')}`
      axios.defaults.headers.Authorization = token
      return <Outlet />;
    }
  }

  return (
    <>
      <ToastContainer position="bottom-right" autoClose="500" closeOnClick="true" />
      <BrowserRouter>
        <Routes>
          <Route element={<ProtectedRoute />}>

            <Route path="/home" element={<Home />} />
            <Route path="/stamps" element={<Stamps />} />
            <Route path="/stamps/add" element={<StampsAdd />} />
            <Route path="/stamps/edit/:id" element={<StampsEdit />} />
            <Route path='/stamps/view/:id' element={<StampsView />} />


            <Route path="/question" element={<Question />} />
            <Route path="/question/add" element={<QuestionAdd />} />
            <Route path='/question/edit/:id' element={<QuestionEdit />} />
            <Route path='/question/view/:id' element={<Questionview />} />

          </Route>
          <Route path='*' element={<Error />} />
          <Route path="/" element={Cookies.get('jwt-Gallary') !== undefined ? <Navigate to="/home" /> : <Login />} />
        </Routes>
      </BrowserRouter>
    </>
  )
}


export default App;
