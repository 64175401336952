import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { QuestionAllView, Questionview } from '../../Auth/Api';
import Layout from '../../layout/Layout';

const QuestionView = () => {

    const [Data, SetData] = useState([])
    const [question, Setquestion] = useState([])
    const [loading, setloading] = useState(false)
    var { id } = useParams()
    const [_id, set_id] = useState(id)

    const GetData = async (viewid) => {
        setloading(true)
        const Result = await Questionview((viewid === "") ? _id : viewid)
        if (Result.data.Status === true) {
            setloading(false)
            SetData(Result.data.Data)
        }
    }

    const Question = async () => {
        const Result = await QuestionAllView()
        if (Result.data.Status === true) {
            Setquestion(Result.data.Data)
        }
    }

    const getinputid = async (e) => {
        set_id(e.target.value)
        GetData(e.target.value)
    }

    useEffect(() => {
        GetData("")
        Question()
    }, [])

    return (
        <>
            {(loading === true) ? <div className="loader" ></div> : <></>}
            <Layout sidebar={true}>
                <div div className="page-heading">
                    <h3><Link to="/question" className='btn btn-primary btn-icon me-3'><i className='bx bxs-left-arrow-alt'></i></Link>View Question</h3>
                    <Row className="align-items-center">
                        <Col md="auto" xs={6}>
                            <Form.Select className="my-1" onChange={(e) => { getinputid(e) }} >
                                {question.map((val, index) => {
                                    return (
                                        <option value={val._id} key={index} selected={(val._id === id) ? true : false} > {val.question}</option>
                                    )
                                })}
                            </Form.Select>
                        </Col>
                        <Col md="auto" xs={6}>
                            <Link to={`/question/edit/${_id} `}>
                                <Button variant="primary" value="edit">Edit</Button>
                            </Link>
                        </Col>
                    </Row>
                </div>
                <div className='page-content'>
                    {/* {loading === true ? <div className='loader table-loader'></div> : ""} */}
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Question</p> <span>{Data.question}</span>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <div className='mb-4'>
                                    <p className='mb-0 fw-bold'>Answer</p> <span>{Data.answer}</span>
                                </div>
                            </Row>
                        </Card.Body>
                    </Card>
                </div>
            </Layout>
        </>
    )
}


export default QuestionView