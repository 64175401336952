import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Row, Breadcrumb } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from '../../layout/Layout';
import { toast } from 'react-toastify';
import Switch from "react-switch";
import { StampEdit, StampUpdate } from '../../Auth/Api';


const StampsEdit = () => {

    const Redirect = useNavigate()
    const [loading, setloading] = useState(false)
    const [Data, Editdata] = useState({
        main_images: "",
        front_images: "",
        zip: "",
        status: 0,
        premium: 0
    })

    const { id } = useParams()

    const EditData = async () => {
        const Result = await StampEdit(id)
        if (Result.data.Status === true) {
            Editdata({
                main_images: Result.data.Data.main_image,
                front_images: Result.data.Data.front_image,
                zip: Result.data.Data.zip_name,
                status: Result.data.Data.status,
                premium: Result.data.Data.is_premium
            })
        }
        else {
            toast.error(Result.data.Response_Message)
        }
    }

    const inputfile = (e) => {
        Editdata({ ...Data, [e.target.name]: e.target.files[0] })
    }

    const inputstatus = (e) => {
        var status = (e === true) ? 1 : 0
        Editdata({ ...Data, status: status })
    }

    const inputPremium = (e) => {
        var premium = (e === true) ? 1 : 0
        Editdata({ ...Data, premium: premium })
    }

    const Update = async () => {
        if (Data.main_images === undefined) {
            toast.error("Main Images Field Is Required")
        }
        else if (Data.front_images === undefined) {
            toast.error('Front Images Field Is Required')
        }
        else if (Data.zip === undefined) {
            toast.error('Zip Field Is Required')
        }
        else {
            setloading(true)
            const Result = await StampUpdate(Data, id)
            if (Result.data.Status === true) {
                toast.success(Result.data.Response_Message)
                Redirect(`/stamps/view/${id}`)
                setloading(false)
            }
            else {
                toast.error(Result.data.Response_Message)
            }
        }
    }

    useEffect(() => {
        EditData()
    }, [])

    return (
        <>
            {(loading === true) ? <div className="loader" ></div> : <></>}
            <Layout sidebar={true}>
                <div className="page-heading">
                    <h3>Edit Stamps</h3>
                    <Breadcrumb className="d-none d-lg-block">
                        <Breadcrumb.Item href='/home'>
                            <i className='bx bx-home-alt me-2 fs-5' ></i> Home
                        </Breadcrumb.Item>
                        <Breadcrumb.Item href='/stamps'>
                            Stamps
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Edit Stamps</Breadcrumb.Item>
                    </Breadcrumb>
                </div>

                <div className="page-content">
                    <Form>
                        <Row>
                            <Col xs={12}>
                                <Card className="mb-4">
                                    <Card.Body>
                                        <Row>
                                            <Row>
                                                <Col lg={4} sm={12}>
                                                    <Form.Group>
                                                        <Form.Label htmlFor="cate-thumb" >Main Images</Form.Label>
                                                        <div className='side-img'>
                                                            <Form.Control type="file" id='cate-thumb' name='main_images' className="my-2 pe-5" onChange={e => inputfile(e)} required />
                                                            <img src={Data.main_images} className='hv-40 bg-light' alt="" />
                                                            <Form.Control.Feedback type="invalid">
                                                                Enter Main Images
                                                            </Form.Control.Feedback>
                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={4} sm={12}>
                                                    <Form.Group>
                                                        <Form.Label htmlFor="cate-bg" id='label'>Front Images</Form.Label>
                                                        <div className='side-img'>
                                                            <Form.Control type="file" name='front_images' id='Print' className="my-2 pe-5" onChange={e => inputfile(e)} required />
                                                            <img src={Data.front_images} className='hv-40 bg-light' alt="" />
                                                            <Form.Control.Feedback type="invalid">
                                                                Enter Front Images
                                                            </Form.Control.Feedback>
                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={4} sm={12}>
                                                    <Form.Group>
                                                        <Form.Label htmlFor="cate-bg" id='label'>Zip Name</Form.Label>
                                                        <div className='side-img'>
                                                            <Form.Control type="file" name='zip' id='Print' className="my-2 pe-5" accept='.zip ,.7zip,.rar' onChange={e => inputfile(e)} required />
                                                            {
                                                                (Data?.zip?.name === undefined && Data?.zip !== undefined) ? <>
                                                                    <a download="GFG" href={Data.zip} >
                                                                        <Button variant="outline-primary" size="sm" className="btn-icon">
                                                                            <i className='bx bxs-download'></i>
                                                                        </Button>
                                                                    </a>
                                                                </> : ""
                                                            }
                                                            <Form.Control.Feedback type="invalid">
                                                                Enter Zip File
                                                            </Form.Control.Feedback>
                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Col md={2}>
                                                <Form.Label htmlFor="media_type" className='d-block mb-2'>Status</Form.Label>
                                                <Switch
                                                    onChange={e => inputstatus(e)}
                                                    checked={(Data.status === 1) ? true : false}
                                                    offColor="#C8C8C8"
                                                    onColor="#0093ed"
                                                    height={30}
                                                    width={70}
                                                    className="react-switch"
                                                    uncheckedIcon={
                                                        <div className='react-switch-off'>OFF</div>
                                                    }
                                                    checkedIcon={
                                                        <div className='react-switch-on'>ON</div>
                                                    }
                                                />
                                            </Col>
                                            <Col md={2}>
                                                <Form.Label htmlFor="media_type" className='d-block mb-2'>Premium</Form.Label>
                                                <Switch
                                                    onChange={e => inputPremium(e)}
                                                    checked={(Data.premium === 1) ? true : false}
                                                    offColor="#C8C8C8"
                                                    onColor="#0093ed"
                                                    height={30}
                                                    width={70}
                                                    className="react-switch"
                                                    uncheckedIcon={
                                                        <div className='react-switch-off'>OFF</div>
                                                    }
                                                    checkedIcon={
                                                        <div className='react-switch-on'>ON</div>
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                    <Card.Footer className="text-end">
                                        <Button variant="primary" className="me-3" onClick={Update} >Update</Button>
                                        <Link to={`/stamps/view/${id}`}>
                                            <Button variant="secondary">Cancle</Button>
                                        </Link>
                                    </Card.Footer>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Layout>
        </>
    )
}

export default StampsEdit;