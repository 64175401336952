import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Row, Col, Button, Form, Card, Breadcrumb } from 'react-bootstrap';
import Layout from '../../layout/Layout';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { toast } from 'react-toastify';
import { QuestionApi } from '../../Auth/Api';


const QuestionAdd = () => {

    const Redirect = useNavigate()
    const [loading, setloading] = useState(false)
    const [Question, setQuestion] = useState({
        question: "",
        answer: ""
    })

    const inputQuestion = (e) => {
        setQuestion({ ...Question, question: e.target.value })
    }

    const inputAnswer = (e, editor) => {
        setQuestion({ ...Question, answer: editor.getData() })
    }

    const Save = async () => {
        if (Question.question === "") {
            toast.error('Question Field Is Required')
        }
        else if (Question.answer === "") {
            toast.error('Answer Field Is Required')
        }
        else {
            setloading(true)
            const Result = await QuestionApi(Question)
            if (Result.data.Status === true) {
                toast.success(Result.data.Response_Message)
                setloading(false)
                Redirect('/question')
            }
            else {
                toast.error(Result.data.Response_Message)
            }
        }
    }

    return (
        <>
            {(loading === true) ? <div className="loader" ></div> : <></>}
            <Layout sidebar={true}>
                <div className="page-heading">
                    <h3>Create Question</h3>
                    <Breadcrumb className="d-none d-lg-block">
                        <Breadcrumb.Item href='/home'>
                            <i className='bx bx-home-alt me-2 fs-5' ></i> Home
                        </Breadcrumb.Item>
                        <Breadcrumb.Item href='/question'>
                        Question
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Create Question</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className="page-content">
                    <Form>
                        <Row>
                            <Col xs={12}>
                                <Card className="mb-4">
                                    <Card.Body>
                                        <Row>
                                            <Row>
                                                <Col lg={4} sm={12}>
                                                    <Form.Group >
                                                        <Form.Label htmlFor="cate" >Question</Form.Label>
                                                        <Form.Control type="text" id='cate' name="question" className="my-2" placeholder='Enter Question' onChange={(e) => inputQuestion(e)} required />
                                                        <Form.Control.Feedback type="invalid">
                                                            Enter Front Images
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={12}>
                                                    <Form.Label htmlFor="cate" >Answer</Form.Label>
                                                    <CKEditor
                                                        editor={ClassicEditor}
                                                        onChange={(e, editor) => inputAnswer(e, editor)}
                                                        required
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Enter Front Images
                                                    </Form.Control.Feedback>
                                                </Col>

                                            </Row>
                                        </Row>
                                    </Card.Body>
                                    <Card.Footer className="text-end">
                                        <Button variant="primary" className="me-3" onClick={Save}  >Save</Button>
                                        <Link to="/question">
                                            <Button variant="secondary">Cancle</Button>
                                        </Link>
                                    </Card.Footer>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Layout>
        </>
    )

}

export default QuestionAdd